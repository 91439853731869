/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {









        $(window).on('scroll', function() {

            ELEMENTS = $(".footer .button-white");
            $(ELEMENTS).each(function() {
              if (isScrolledIntoView($(this))) {
      
                $(this).delay(70000).addClass('fadeup');
                $(this).delay(70000).addClass('wobble-hor-bottom');
                
              } else {

                $(this).removeClass('fadeup');
                $(this).removeClass('wobble-hor-bottom');


              }

            });

          });


        



        



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


        // var client =  $('#red_testimonials').find('.item.next');
        
        var count = $('#red_testimonials .item').length;
        $('.countertotal').text(count);
        // alert(count)
        var current = $('.counter').text();
        newtotal = parseInt(current)+1;
        if (newtotal > count){
          $('.counter').text('1');
        } else {
          $('.counter').text(newtotal);
        }



         //redtestimonials();

         $("#red_testimonials").carousel('pause');
         $('#red_testimonials').on('slide.bs.carousel', function () {

            redtestimonials();

         });

          


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };




        function redtestimonials() {

                  var client =  $('#red_testimonials').find('.item.start'); 
                  var client2 =  $('#red_testimonials').find('.item.active').next();
                  //alert(client)
                  if (client2.length != 1 ) {
 
                    var title = client.attr('data-project');
                    var href = client.attr('data-link');
                    $('.clientname').html('<a href="'+href+'">'+title+'</a>');

                  } else {

                    var title = client2.attr('data-project');
                    var href = client2.attr('data-link');
                    $('.clientname').html('<a href="'+href+'">'+title+'</a>');

                  }
                  
                 
                  // $('.clientname').attr('href',href);
                  var count = $('#red_testimonials .item').length;
                  $('.countertotal').text(count);
                  // alert(count)
                  var current = $('.counter').text();
                  newtotal = parseInt(current)+1;
                  if (newtotal > count){
                    $('.counter').text('1');
                  } else {
                    $('.counter').text(newtotal);
                  }

        }



        function isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();

            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));

        }

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
